import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, PaginationSimple, Audio } from "../components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import slugifyPost from "../../slugifyPost"

const SingleAudioAlbumPage = ({ data, pageContext }) => {
  const {
    audioAlbumTitle,
    performer,
    publisher,
    audioPublished,
    category,
    audioAlbumText,
    audiosong,
  } = data.contentfulAudioAlbum

  console.log("audiosong", audiosong)
  console.log("link", audiosong[0].audioSongLink)
  console.log("title", audiosong.audioSongTitle)

  const image = getImage(data.contentfulAudioAlbum.audioAlbumImage)

  const { previous, next } = pageContext

  const prevTitle = previous ? previous.audioAlbumTitle : null
  const nextTitle = next ? next.audioAlbumTitle : null
  const prevRow = previous
    ? slugifyPost(previous.performer.performerName)
    : null
  const nextRow = next ? slugifyPost(previous.performer.performerName) : null
  // const allItemPage = `/audio/${category.categoryName}s/1`
  const allItemPage = `/audio/1`
  const oneItemPage = `/audio/${category.categoryName}/`

  return (
    <Wrapper>
      <Layout>
        <div className="section-center">
          <h1>{audioAlbumTitle}</h1>
          <h2>{performer.performerName}</h2>
          <h4 className="publisher">
            {publisher.publisherName} | {audioPublished}
          </h4>

          <Audio
            audioSrcUrl={audiosong[0].audioSongLink}
            audioTitle={audiosong[0].audioSongTitle}
            className="audio"
          />

          <p
            className="info"
            dangerouslySetInnerHTML={{
              __html: audioAlbumText.childMarkdownRemark.html,
            }}
          ></p>

          <GatsbyImage image={image} className="img" alt="Album cover" />
        </div>

        <PaginationSimple
          oneItemPage={oneItemPage}
          allItemPage={allItemPage}
          prevTitle={prevTitle}
          nextTitle={nextTitle}
          prevRow={prevRow}
          nextRow={nextRow}
          pageContext={pageContext}
        />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  background: var(--clr-light-5);
  padding-top: 9rem;
  padding-bottom: 0rem;
  color: var(--clr-primary-4);

  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }

  .section-center {
    h2 {
      text-align: center;
      padding-bottom: 2rem;
    }

    h3 {
      ${"" /* text-align: center; */}
      padding-bottom: 1rem;
      padding-top: 1rem;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
    }

    h4 {
      text-align: center;
      text-transform: uppercase;
      ${"" /* padding-bottom: 1rem; */}
    }

    .publisher {
      font-weight: bold;
      padding-bottom: 2rem;
    }

    .info {
      padding-left: 10%;
      padding-bottom: 2rem;
      color: var(--clr-grey-1);
      border-left: 2px solid var(--clr-primary-5);
    }
  }

  .img {
    border-radius: var(--radius);
    transition: var(--transition);
  }

  a {
    ${"" /* display: block; */}
    width: auto;
    text-align: center;
    ${"" /* padding: 1rem 1rem; */}
    margin: 0 auto;
    ${"" /* margin-top: 1rem; */}
    color: var(--clr-light-3);
  }

  .btn {
    color: var(--clr-primary-22);
  }

  .btn:hover {
    color: var(--clr-primary-1);
  }

  @media (min-width: 768px) {
    .section-center {
      width: 70%;
    }
  }
  @media (min-width: 992px) {
    .section-center {
      width: 50%;
    }
  }
  @media (min-width: 1200px) {
    .section-center {
      width: 40%;
    }
  }
`

export const query = graphql`
  query($recordId: String!) {
    contentfulAudioAlbum(contentful_id: { eq: $recordId }) {
      contentful_id
      audioAlbumTitle
      performer {
        performerName
      }
      publisher {
        publisherName
      }
      audioPublished
      category {
        categoryName
      }
      audioAlbumText {
        childMarkdownRemark {
          html
        }
      }
      audioAlbumImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      }
      audiosong {
        audioSongTitle
        audioSongLink
        contentful_id
      }
    }
  }
`

export default SingleAudioAlbumPage
